import { registerBlockType } from '@wordpress/blocks';
import { __ } from '@wordpress/i18n'


/**
 * Internal dependencies
 */
import Edit from './edit';
import save from './save';

import blockIcons from '../icons.js';

/**
 * Every block starts by registering a new block type definition.
 *
 * @see https://developer.wordpress.org/block-editor/developers/block-api/#registering-a-block
 */
registerBlockType( 'gco/cta', {
	apiVersion: 2,
	title: __( 'Appel à l\'action', 'gracietco-gut' ),
	description: __(
		'Créez et sauvegardez des appels à l\'action pour mettre en avant des liens vers vos pages ou ressources importantes.',
		'gracietco-gut'
	),
  parent: [ 'gco/place', 'gco/section'], 
	category: 'widgets',
	icon: blockIcons.cta,
  	keywords: [ __( 'cta', 'gracietco-gut' ),  __( 'appel a l\'action', 'gracietco-gut' )],
	supports: {
		html: false,
		/*color: {
			text: true,
			gradients: false,
			background: false,
		}*/
	},
  example: {
    attributes: {
      isLarge: true,
      title: "Appel à l'action",
      catchphrase: "Accroche"
    }
  },
	attributes: {
		isLarge: {
            type: 'boolean',
			default: true
        },
		alignLeft: {
            type: 'boolean',
			default: true
        },
		haveIcon: {
            type: 'boolean',
			default: false
        },
		iconColor: {
			type: 'string',
			default: 'text-'
		},
		backgroundColor: {
			type: 'string',
			default: 'bg-gradient-primary'
		},
		title: {
            type: 'string',
            default: ''
        },
        catchphrase: {
            type: 'string',
            default: ''
        },
		mediaId: {
			type: 'number',
			default: 0
		},
		mediaUrl: {
			type: 'string',
			default: ''
		},
		iconName: {
            type: 'string',
            default: ''
        },
        linkStyle: {
			type: 'string',
			default: 'btn btn-wave btn-primary'
		},
        linkUrl: {
			type: 'string',
			source: 'attribute',
			selector: 'a',
			attribute: 'href'
		},
		linkText: {
			type: 'string',
			default: 'En savoir plus'
		},
		linkTarget: {
			type: 'boolean',
			default: false
		},
		linkDownload: {
			type: 'boolean',
			default: false
		},
		linkRel: {  
			type: 'string',
			source: 'attribute',
			selector: 'a',
			attribute: 'rel'
        },
		customAnchor: {
			type: 'string',
			default : ''
    },
    niveauTitre: {
      type: 'string',
      default: 'h2'
    }
	},
	edit: Edit,
	save,
} );

