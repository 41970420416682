import L from 'leaflet';

const icon = '<svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 0 24 24" width="48px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>';

const primaryIcon = new L.divIcon({
  className: "leaflet-icon",
  iconAnchor: null,
  labelAnchor: null,
  iconSize: new L.Point(60, 75),
  html: '<span class="text-primary-300">'+icon+'</span>'
});

const secondaryIcon = new L.divIcon({
  className: "leaflet-icon",
  iconAnchor: null,
  labelAnchor: null,
  iconSize: new L.Point(60, 75),
  html: '<span class="text-secondary-500">'+icon+'</span>'
});

export { primaryIcon, secondaryIcon };
